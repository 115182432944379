.info-block {
  text-align: justify;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-indent: 20px;
}

.info-list li {
  margin-bottom: 16px;
}

.info-list-tittle {
  font-weight: 600;
  text-indent: 0;
  margin-bottom: 10px;
}

.info-list-content {
  text-indent: 0;
}